import React, { useContext } from "react";
import { ProfileContext } from "../../context/ProfileContext";

import styles from "./Avatar.module.scss";

export const Avatar = () => {
  const { profile } = useContext(ProfileContext);

  return (
    <img className={styles.avatar} src={profile?.profile_image} alt="img" />
  );
};
