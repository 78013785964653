// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Section_section__0XkUm {
  padding: 20px;
  background: #3D3D3D;
  border-radius: 10px;
}
@media (max-width: 1200px) {
  .Section_section__0XkUm {
    padding: 8px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Section/Section.module.scss","webpack://./src/styles/_mixins.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;AADJ;ACAI;EDFJ;IAMQ,YAAA;EAAN;AACF","sourcesContent":["@import '../../styles/mixins';\n\n.section {\n    padding: 20px;\n    background: #3D3D3D;\n    border-radius: 10px;\n\n    @include media-down($desktop) {\n        padding: 8px;\n    }\n}","$desktop: 1200px;\n$tablet: 780px;\n\n@mixin media($width, $type: max) {\n    @media (#{$type}-width: #{$width}) {\n        @content;\n    }\n  }\n  \n  @mixin media-up($width) {\n    @include media($width + 1px, min) {\n        @content;\n    }\n  }\n  \n  @mixin media-down($width) {\n    @include media($width , max) {\n        @content;\n    }\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `Section_section__0XkUm`
};
export default ___CSS_LOADER_EXPORT___;
