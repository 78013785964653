import React from "react";

export const TgIconSmall = (props?: React.HTMLAttributes<HTMLOrSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.82162 7.81774C6.04219 6.41909 8.18616 5.48973 9.26275 5.03885C12.3269 3.75983 12.971 3.53899 13.3851 3.52979C13.4771 3.52979 13.6795 3.54819 13.8175 3.65861C13.928 3.75062 13.9556 3.87025 13.974 3.96226C13.9924 4.05428 14.0108 4.24751 13.9924 4.39474C13.8267 6.14304 13.109 10.385 12.741 12.3357C12.5845 13.1639 12.2809 13.4399 11.9864 13.4675C11.3423 13.5227 10.8546 13.0443 10.2381 12.6394C9.26275 12.0045 8.71986 11.6088 7.77209 10.9831C6.6771 10.2654 7.38562 9.8697 8.01133 9.22558C8.17696 9.05995 11.0019 6.4835 11.0571 6.25346C11.0663 6.22586 11.0663 6.11544 11.0019 6.06023C10.9374 6.00502 10.8454 6.02342 10.7718 6.04183C10.6706 6.06023 9.12473 7.09081 6.1158 9.12436C5.67412 9.42802 5.27845 9.57524 4.91959 9.56604C4.52392 9.55684 3.76939 9.3452 3.19889 9.16117C2.50877 8.94033 1.95667 8.82071 2.00268 8.43424C2.03029 8.23181 2.30633 8.02937 2.82162 7.81774Z"
        fill="#F5F5F5"
      />
    </svg>
  );
};
