import React from "react";

import { DateField, LocalizationProvider } from "@mui/x-date-pickers";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ruRU } from "@mui/x-date-pickers/locales";
import { Dayjs } from "dayjs";

interface InputProps {
  value: Dayjs | null;
  onChange: (value: Dayjs | null) => void;
  label: string;
  required?: boolean;
}

export const DateInput = (props: InputProps) => {
  const { value, onChange, label, required } = props;
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      localeText={
        ruRU.components.MuiLocalizationProvider.defaultProps.localeText
      }
    >
      <DateField
        sx={{
          "& .Mui-focused": {
            color: "#535353 !important",
          },
          "& .MuiInputBase-root.MuiInput-root.MuiInput-underline::after": {
            borderBottom: "2px solid #535353",
          },
          "& .MuiFormHelperText-root": {
            color: "var(--color-grey)",
            fontSize: "10px",
            letterSpacing: "0.2px",
          },
        }}
        label={label}
        required={required}
        format="DD.MM.YYYY"
        value={value}
        style={{ width: "100%" }}
        variant="standard"
        onChange={(e) => onChange(e)}
      />
    </LocalizationProvider>
  );
};
