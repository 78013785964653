import React from "react";

export const StarSpriteIcon = (props: React.HTMLAttributes<SVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <path d="M7.38001 1.97117C7.57516 1.34294 8.42484 1.34294 8.61999 1.97118L9.70753 5.47229C9.7948 5.75324 10.0451 5.94346 10.3275 5.94346H13.8469C14.4784 5.94346 14.7409 6.78874 14.23 7.17701L11.3828 9.34082C11.1543 9.51445 11.0587 9.82224 11.146 10.1032L12.2335 13.6043C12.4287 14.2325 11.7413 14.7549 11.2304 14.3667L8.38317 12.2029C8.15469 12.0292 7.84531 12.0292 7.61683 12.2029L4.76962 14.3667C4.25872 14.7549 3.57131 14.2325 3.76646 13.6043L4.85399 10.1032C4.94126 9.82224 4.84566 9.51445 4.61718 9.34081L1.76997 7.17701C1.25907 6.78874 1.52164 5.94346 2.15314 5.94346H5.67249C5.95491 5.94346 6.2052 5.75324 6.29248 5.47229L7.38001 1.97117Z" />
    </svg>
  );
};
