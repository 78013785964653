import axios, { AxiosError } from "axios";
import {
  CreateProjectData,
  FilteredTableData,
  GetProjectsData,
  GetProjectsDataResponse,
  ProjectToShowData,
} from "../types/Projects";
import {
  ChangePasswordPayloadData,
  CompleteRegPayload,
  ConfirmChangePasswordPayloadData,
  ConfirmSignUpPayloadData,
  GetRefreshTokenPayloadData,
  GetRefreshTokenResponseData,
  ResendCodeToConfirmPayload,
  SendCodePayloadData,
  SignUpPayloadData,
  SignUpResponseData,
} from "../types/Auth";
import { property } from "lodash-es";
import Cookies from "js-cookie";
import { UserPopupData } from "../types/User";

const instance = axios.create({
  baseURL: "https://capital.recscommunity.ru",
});

const authInstance = axios.create({
  baseURL: "https://auth.recscommunity.ru/api_v2/",
});

const recsInstance = axios.create({
  baseURL: "https://api.recscommunity.ru",
});

export const api = {
  addProject(data: CreateProjectData) {
    return instance.post("/api/projects/create", data);
  },
  getProject(id: number): Promise<ProjectToShowData> {
    return instance
      .get(`/api/projects/show`, {
        params: {
          project_id: id,
        },
      })
      .then(property("data"));
  },
  uploadProjectImage(data: FormData): Promise<{ icon_name: string }> {
    return instance
      .post("/api/projects/upload_icon", data)
      .then(property("data"));
  },
  filteredTable(data: FilteredTableData): Promise<{ amount: number }> {
    return instance
      .get("/api/projects/amount_filtered_obj", {
        params: {
          ...data,
        },
      })
      .then(property("data"));
  },
  getProjects(data: GetProjectsData): Promise<GetProjectsDataResponse[]> {
    return instance
      .get("/api/projects", { params: data })
      .then(property("data"));
  },

  getDefaultFilterValues(): Promise<FilteredTableData> {
    return instance.get("/api/projects/restrictions").then(property("data"));
  },
};

export const authApi = {
  signUp(data: SignUpPayloadData): Promise<SignUpResponseData> {
    return authInstance.post("sign_up", data).then(property("data"));
  },
  confirmSignUp(data: ConfirmSignUpPayloadData): Promise<SignUpResponseData> {
    return authInstance.put("confirm_sign_up", data).then(property("data"));
  },
  getRefreshToken(
    data: GetRefreshTokenPayloadData
  ): Promise<GetRefreshTokenResponseData> {
    data.life_time_minutes = 60 * 24 * 360;

    return authInstance.post("get_refresh_token", data).then(property("data"));
  },
  changePassword(data: ChangePasswordPayloadData): Promise<number> {
    return authInstance.post("change_password", data).then(property("data"));
  },
  sendCode(data: SendCodePayloadData): Promise<number> {
    return authInstance
      .get("check_valid_pass_code", {
        params: {
          login: data.login,
          code: data.code,
        },
      })
      .then(property("data"));
  },
  confirmChangePassword(
    data: ConfirmChangePasswordPayloadData
  ): Promise<number> {
    return authInstance
      .put("confirm_change_password", data)
      .then(property("data"));
  },

  resendCodeToConfirm(
    data: ResendCodeToConfirmPayload
  ): Promise<SignUpResponseData> {
    return authInstance
      .post("resend_registration_code", data)
      .then(property("data"));
  },

  refreshSession(refreshToken: string) {
    return authInstance.post("/api_v2/refresh_session").then(property("data"));
  },

  getUserData(uuid?: string): Promise<UserPopupData> {
    return authInstance
      .get("/capital/user_pop_up", {
        params: {
          uuid,
        },
      })
      .then(property("data"));
  },

  completeFullReg(data: CompleteRegPayload) {
    return authInstance
      .put("/capital/complete_reg", data)
      .then(property("data"));
  },

  checkFullReg(): Promise<{ is_full_reg: boolean }> {
    return authInstance.get("/capital/check_reg").then(property("data"));
  },
};

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("access_token");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
});

export const recsApi = {
  getUserData(uuid?: string): Promise<UserPopupData> {
    return recsInstance
      .get("/user_pop_up", {
        params: {
          uuid,
        },
      })
      .then(property("data"));
  },
  checkFullReg(): Promise<{ is_full_reg: boolean }> {
    return recsInstance.get("/capital/check_reg").then(property("data"));
  },
  completeFullReg(data: CompleteRegPayload) {
    return recsInstance
      .put("/capital/complete_reg", data)
      .then(property("data"));
  },
};

recsInstance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("access_token");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
});

authInstance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("access_token");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
});
