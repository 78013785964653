// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Star_rank__RENP6 {
  margin-left: 4px;
  max-height: 16px;
}
.Star_rank__RENP6.Star_isResident__SOjVX {
  color: var(--black);
  border: 1px solid var(--orange-500);
  background: var(--white);
}
.Star_rank__RENP6.Star_isExpert__UPVXC {
  background: var(--orange-400);
}
.Star_rank__RENP6.Star_isAmbassador__omzOH {
  background: var(--red-400);
}
.Star_rank__RENP6.Star_isAdvisor__oiOWl {
  border: 1px solid var(--red-500);
  color: var(--black);
  background: var(--white);
}
.Star_rank__RENP6.Star_isHeadliner__SWsVD {
  background: var(--red-600);
}`, "",{"version":3,"sources":["webpack://./src/components/Star/Star.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,gBAAA;AACJ;AACI;EACI,mBAAA;EACA,mCAAA;EACA,wBAAA;AACR;AAEI;EACI,6BAAA;AAAR;AAGI;EACI,0BAAA;AADR;AAII;EACI,gCAAA;EACA,mBAAA;EACA,wBAAA;AAFR;AAKI;EACI,0BAAA;AAHR","sourcesContent":[".rank {\n    margin-left: 4px;\n    max-height: 16px;\n    \n    &.isResident {\n        color: var(--black);\n        border: 1px solid var(--orange-500);\n        background: var(--white);\n    }\n\n    &.isExpert {\n        background: var(--orange-400);\n    }\n\n    &.isAmbassador {\n        background: var(--red-400);\n    }\n\n    &.isAdvisor {\n        border: 1px solid var(--red-500);\n        color: var(--black);\n        background: var(--white);\n    }\n\n    &.isHeadliner {\n        background: var(--red-600);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rank": `Star_rank__RENP6`,
	"isResident": `Star_isResident__SOjVX`,
	"isExpert": `Star_isExpert__UPVXC`,
	"isAmbassador": `Star_isAmbassador__omzOH`,
	"isAdvisor": `Star_isAdvisor__oiOWl`,
	"isHeadliner": `Star_isHeadliner__SWsVD`
};
export default ___CSS_LOADER_EXPORT___;
