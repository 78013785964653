//react, next
import React from "react";

export const BigStarIcon = (props: { size?: number; fill?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.size ?? 16}
      height={props?.size ?? 16}
      viewBox="0 0 16 16"
      fill={props.fill ?? "var(--white)"}
      style={{ flexShrink: 0 }}
    >
      <path d="M8.303 2.218a.724.724 0 0 1 1.394 0l1.224 3.938c.098.316.38.53.697.53h3.96c.71 0 1.006.951.43 1.388l-3.202 2.434a.787.787 0 0 0-.267.858l1.224 3.939c.22.707-.554 1.294-1.129.858l-3.203-2.435a.708.708 0 0 0-.862 0l-3.203 2.435c-.575.436-1.348-.151-1.129-.858l1.224-3.939a.787.787 0 0 0-.267-.858L1.991 8.074c-.575-.437-.28-1.388.431-1.388h3.96c.317 0 .599-.214.697-.53l1.224-3.938Z" />
    </svg>
  );
};
