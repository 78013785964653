// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page {
  padding: 20px;
  /* height: 100%; */
  max-width: 1160px;
  margin: 0 auto;
}
@media (max-width: 1200px) {
  .page {
    padding: 16px 8px;
  }
}

h1,
h2,
h3,
h4 {
  margin: 0;
}

.h1 {
  font-size: 33px;
  line-height: 43px;
  font-weight: 700;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .h1 {
    font-size: 28px;
    line-height: 36px;
  }
}`, "",{"version":3,"sources":["webpack://./src/App.scss","webpack://./src/styles/_mixins.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,kBAAA;EACA,iBAAA;EACA,cAAA;AADF;ACDI;EDFJ;IAOI,iBAAA;EAAF;AACF;;AAGA;;;;EAIE,SAAA;AAAF;;AAGA;EACE,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,yBAAA;AAAF;ACpBI;EDgBJ;IAOI,eAAA;IACA,iBAAA;EACF;AACF","sourcesContent":["@import \"./styles/_mixins\";\n\n.page {\n  padding: 20px;\n  /* height: 100%; */\n  max-width: 1160px;\n  margin: 0 auto;\n\n  @include media-down($desktop) {\n    padding: 16px 8px;\n  }\n}\n\nh1,\nh2,\nh3,\nh4 {\n  margin: 0;\n}\n\n.h1 {\n  font-size: 33px;\n  line-height: 43px;\n  font-weight: 700;\n  text-transform: uppercase;\n\n  @include media-down($desktop) {\n    font-size: 28px;\n    line-height: 36px;\n  }\n}\n","$desktop: 1200px;\n$tablet: 780px;\n\n@mixin media($width, $type: max) {\n    @media (#{$type}-width: #{$width}) {\n        @content;\n    }\n  }\n  \n  @mixin media-up($width) {\n    @include media($width + 1px, min) {\n        @content;\n    }\n  }\n  \n  @mixin media-down($width) {\n    @include media($width , max) {\n        @content;\n    }\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
