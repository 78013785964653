import React, { HTMLAttributes, useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./Star.module.scss";
import { OneStarIcon } from "../../icons/OneStarIcon";
import { TwoStarsIcon } from "../../icons/TwoStarIcon";
import { ThreeStarIcon } from "../../icons/ThreeStarIcon";
import { FourStarIcon } from "../../icons/FourStarIcon";
import { BigStarIcon } from "../../icons/BigStarIcon";

// Все значения объекта UserRanks
export type RankType =
  | "guest"
  | "recommended"
  | "resident"
  | "expert"
  | "ambassador"
  | "advisor"
  | "headliner";

export enum UserRanks {
  GUEST = "guest",
  RECOMMENDED = "recommended",
  RESIDENT = "resident",
  EXPERT = "expert",
  AMBASSADOR = "ambassador",
  ADVISOR = "advisor",
  HEADLINER = "headliner",
}
type Rank = {
  type: RankType;
  icon: React.JSX.Element | null;
  class: string;
};

export const ranks: Rank[] = [
  {
    type: UserRanks.GUEST,
    icon: null,
    class: styles.isResident,
  },
  {
    type: UserRanks.RECOMMENDED,
    icon: null,
    class: styles.isResident,
  },
  {
    type: UserRanks.RESIDENT,
    icon: <OneStarIcon fill="#F5723E" />,
    class: styles.isResident,
  },
  {
    type: UserRanks.EXPERT,
    icon: <TwoStarsIcon fill="#F5723E" />,
    class: styles.isResident,
  },
  {
    type: UserRanks.AMBASSADOR,
    icon: <ThreeStarIcon fill="#F14040" />,
    class: styles.isExpert,
  },
  {
    type: UserRanks.ADVISOR,
    icon: <FourStarIcon fill="#E01A3E" />,
    class: styles.isAmbassador,
  },
  {
    type: UserRanks.HEADLINER,
    icon: <BigStarIcon fill="#CB1939" />,
    class: styles.isAdvisor,
  },
];

export type RankChipProps = HTMLAttributes<HTMLDivElement> & {
  type: string;
};

export default function RankStar({ type, className, ...rest }: RankChipProps) {
  const [rank, setRank] = useState<Rank>(ranks[0]);
  useEffect(() => {
    const rank = ranks.find((item) => item.type === type);

    if (rank) {
      setRank(rank);
    }
  }, [type]);

  return (
    <div {...rest} className={classNames(rank?.class, className, styles.rank)}>
      {rank.icon}
    </div>
  );
}
