//react, next
import React from "react";

export const FourStarIcon = (props: { size?: number; fill?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.size ?? 16}
      height={props?.size ?? 16}
      viewBox="0 0 16 16"
      fill={props?.fill ?? "var(--white)"}
      style={{ flexShrink: 0 }}
    >
      <path d="M4.4877 9.69934C4.57026 9.43355 4.92974 9.43355 5.0123 9.69934L5.47241 11.1806C5.50934 11.2994 5.61523 11.3799 5.73472 11.3799H7.22367C7.49085 11.3799 7.60193 11.7375 7.38578 11.9018L6.18119 12.8173C6.08453 12.8907 6.04408 13.0209 6.081 13.1398L6.54111 14.6211C6.62368 14.8868 6.33285 15.1079 6.1167 14.9436L4.91211 14.0281C4.81545 13.9547 4.68455 13.9547 4.58789 14.0281L3.3833 14.9436C3.16715 15.1079 2.87632 14.8868 2.95889 14.6211L3.419 13.1398C3.45592 13.0209 3.41547 12.8907 3.31881 12.8173L2.11422 11.9018C1.89807 11.7375 2.00916 11.3799 2.27633 11.3799H3.76528C3.88477 11.3799 3.99066 11.2994 4.02759 11.1806L4.4877 9.69934Z" />
      <path d="M4.4877 2.19934C4.57026 1.93355 4.92974 1.93355 5.0123 2.19934L5.47241 3.68058C5.50934 3.79945 5.61523 3.87993 5.73472 3.87993H7.22367C7.49085 3.87993 7.60193 4.23754 7.38578 4.40181L6.18119 5.31727C6.08453 5.39073 6.04408 5.52095 6.081 5.63981L6.54111 7.12105C6.62368 7.38684 6.33285 7.60786 6.1167 7.44359L4.91211 6.52814C4.81545 6.45468 4.68455 6.45468 4.58789 6.52814L3.3833 7.4436C3.16715 7.60786 2.87632 7.38684 2.95889 7.12105L3.419 5.63981C3.45592 5.52095 3.41547 5.39073 3.31881 5.31727L2.11422 4.40181C1.89807 4.23754 2.00916 3.87993 2.27633 3.87993H3.76528C3.88477 3.87993 3.99066 3.79945 4.02759 3.68058L4.4877 2.19934Z" />
      <path d="M11.9877 9.69934C12.0703 9.43355 12.4297 9.43355 12.5123 9.69934L12.9724 11.1806C13.0093 11.2994 13.1152 11.3799 13.2347 11.3799H14.7237C14.9908 11.3799 15.1019 11.7375 14.8858 11.9018L13.6812 12.8173C13.5845 12.8907 13.5441 13.0209 13.581 13.1398L14.0411 14.6211C14.1237 14.8868 13.8328 15.1079 13.6167 14.9436L12.4121 14.0281C12.3154 13.9547 12.1846 13.9547 12.0879 14.0281L10.8833 14.9436C10.6672 15.1079 10.3763 14.8868 10.4589 14.6211L10.919 13.1398C10.9559 13.0209 10.9155 12.8907 10.8188 12.8173L9.61422 11.9018C9.39807 11.7375 9.50916 11.3799 9.77633 11.3799H11.2653C11.3848 11.3799 11.4907 11.2994 11.5276 11.1806L11.9877 9.69934Z" />
      <path d="M11.9877 2.19934C12.0703 1.93355 12.4297 1.93355 12.5123 2.19934L12.9724 3.68058C13.0093 3.79945 13.1152 3.87993 13.2347 3.87993H14.7237C14.9908 3.87993 15.1019 4.23754 14.8858 4.40181L13.6812 5.31727C13.5845 5.39073 13.5441 5.52095 13.581 5.63981L14.0411 7.12105C14.1237 7.38684 13.8328 7.60786 13.6167 7.44359L12.4121 6.52814C12.3154 6.45468 12.1846 6.45468 12.0879 6.52814L10.8833 7.4436C10.6672 7.60786 10.3763 7.38684 10.4589 7.12105L10.919 5.63981C10.9559 5.52095 10.9155 5.39073 10.8188 5.31727L9.61422 4.40181C9.39807 4.23754 9.50916 3.87993 9.77633 3.87993H11.2653C11.3848 3.87993 11.4907 3.79945 11.5276 3.68058L11.9877 2.19934Z" />
    </svg>
  );
};
