//react, next
import React from "react";

interface OneStarIconProps {
  fill?: string;
  size?: number;
}

export const OneStarIcon: React.FC<OneStarIconProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.size ?? 16}
      height={props?.size ?? 16}
      viewBox="0 0 18 18"
      fill={props?.fill ?? "var(--white)"}
      style={{ flexShrink: 0 }}
    >
      <path d="M8.2377 6.19934C8.32026 5.93355 8.67974 5.93355 8.7623 6.19934L9.22241 7.68058C9.25934 7.79945 9.36523 7.87993 9.48472 7.87993H10.9737C11.2408 7.87993 11.3519 8.23754 11.1358 8.40181L9.93119 9.31727C9.83453 9.39073 9.79408 9.52095 9.831 9.63981L10.2911 11.1211C10.3737 11.3868 10.0828 11.6079 9.8667 11.4436L8.66211 10.5281C8.56545 10.4547 8.43455 10.4547 8.33789 10.5281L7.1333 11.4436C6.91715 11.6079 6.62632 11.3868 6.70889 11.1211L7.169 9.63981C7.20592 9.52095 7.16547 9.39073 7.06881 9.31727L5.86422 8.40181C5.64807 8.23754 5.75916 7.87993 6.02633 7.87993H7.51528C7.63477 7.87993 7.74066 7.79945 7.77759 7.68058L8.2377 6.19934Z" />
    </svg>
  );
};
