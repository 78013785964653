//react, next
import React from "react";

export const TwoStarsIcon = (props: { size?: number; fill?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.size ?? "16"}
      height={props?.size ?? "16"}
      viewBox="0 0 16 16"
      fill={props?.fill ?? "var(--white)"}
      style={{ flexShrink: 0 }}
    >
      <path d="M8.2377 2.19934C8.32026 1.93355 8.67974 1.93355 8.7623 2.19934L9.22241 3.68058C9.25934 3.79945 9.36523 3.87993 9.48472 3.87993H10.9737C11.2408 3.87993 11.3519 4.23754 11.1358 4.40181L9.93119 5.31727C9.83453 5.39073 9.79408 5.52095 9.831 5.63981L10.2911 7.12105C10.3737 7.38684 10.0828 7.60786 9.8667 7.44359L8.66211 6.52814C8.56545 6.45468 8.43455 6.45468 8.33789 6.52814L7.1333 7.4436C6.91715 7.60786 6.62632 7.38684 6.70889 7.12105L7.169 5.63981C7.20592 5.52095 7.16547 5.39073 7.06881 5.31727L5.86422 4.40181C5.64807 4.23754 5.75916 3.87993 6.02633 3.87993H7.51528C7.63477 3.87993 7.74066 3.79945 7.77759 3.68058L8.2377 2.19934Z" />
      <path d="M8.2377 9.69934C8.32026 9.43355 8.67974 9.43355 8.7623 9.69934L9.22241 11.1806C9.25934 11.2994 9.36523 11.3799 9.48472 11.3799H10.9737C11.2408 11.3799 11.3519 11.7375 11.1358 11.9018L9.93119 12.8173C9.83453 12.8907 9.79408 13.0209 9.831 13.1398L10.2911 14.6211C10.3737 14.8868 10.0828 15.1079 9.8667 14.9436L8.66211 14.0281C8.56545 13.9547 8.43455 13.9547 8.33789 14.0281L7.1333 14.9436C6.91715 15.1079 6.62632 14.8868 6.70889 14.6211L7.169 13.1398C7.20592 13.0209 7.16547 12.8907 7.06881 12.8173L5.86422 11.9018C5.64807 11.7375 5.75916 11.3799 6.02633 11.3799H7.51528C7.63477 11.3799 7.74066 11.2994 7.77759 11.1806L8.2377 9.69934Z" />
    </svg>
  );
};
