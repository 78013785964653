import React from "react";

import TextField from "@mui/material/TextField";

interface InputProps {
  value: string | number;
  onChange: (value: string) => void;
  onBlur?: () => void;
  name: string;
  required?: boolean;
  label: string;
  hint?: string;
  maxLength?: number;
  multiline?: boolean;
  disabled?: boolean;
  type?: string;
  minLength?: number;
  isError?: boolean;
}

export const Input = React.memo(function Input(props: InputProps) {
  const {
    value,
    onChange,
    name,
    required,
    label,
    hint,
    maxLength,
    multiline = true,
    disabled = false,
    type = "text",
    minLength,
    isError,
    onBlur,
  } = props;

  return (
    <TextField
      name={name}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onBlur={(e) => onBlur?.()}
      required={required}
      variant="standard"
      label={label}
      helperText={hint}
      style={{ width: "100%" }}
      multiline={multiline}
      disabled={disabled}
      error={isError}
      slotProps={{
        htmlInput: {
          maxLength: maxLength,
          minLength: minLength,
        },
      }}
      type={type}
      // inputProps={{
      //   maxLength: maxLength,

      // }}
      sx={{
        "& .Mui-focused": {
          color: "#535353 !important",
        },
        "& .MuiInputBase-root.MuiInput-root.MuiInput-underline::after": {
          borderBottom: "2px solid #535353",
        },
        "& .MuiFormHelperText-root": {
          color: "var(--color-grey)",
          fontSize: "10px",
          letterSpacing: "0.2px",
        },
        "& .MuiFormLabel-root.MuiInputLabel-root": {
          display: "flex",
          flexDirection: "row-reverse",
          justifyContent: "flex-end",
        },
        "& input[type=number]": {
          "-moz-appearance": "textfield",
        },
        "& input[type=number]::-webkit-outer-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
        "& input[type=number]::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
      }}
    />
  );
});
