import React from "react";

export const FilterLetterReverseIcon = (
  props: React.HTMLAttributes<HTMLOrSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.46967 3.46967C6.76256 3.17678 7.23744 3.17678 7.53033 3.46967L11.5303 7.46967C11.8232 7.76256 11.8232 8.23744 11.5303 8.53033C11.2374 8.82322 10.7626 8.82322 10.4697 8.53033L7.75 5.81066V20C7.75 20.4142 7.41421 20.75 7 20.75C6.58579 20.75 6.25 20.4142 6.25 20V5.81066L3.53033 8.53033C3.23744 8.82322 2.76256 8.82322 2.46967 8.53033C2.17678 8.23744 2.17678 7.76256 2.46967 7.46967L6.46967 3.46967ZM17.5 4.75C17.0359 4.75 16.5908 4.93437 16.2626 5.26256C15.9344 5.59075 15.75 6.03587 15.75 6.5V7.25H19.25V6.5C19.25 6.03587 19.0656 5.59075 18.7374 5.26256C18.4092 4.93437 17.9641 4.75 17.5 4.75ZM20.75 6.5C20.75 5.63805 20.4076 4.8114 19.7981 4.2019C19.1886 3.59241 18.362 3.25 17.5 3.25C16.638 3.25 15.8114 3.59241 15.2019 4.2019C14.5924 4.8114 14.25 5.63805 14.25 6.5V10C14.25 10.4142 14.5858 10.75 15 10.75C15.4142 10.75 15.75 10.4142 15.75 10V8.75H19.25V10C19.25 10.4142 19.5858 10.75 20 10.75C20.4142 10.75 20.75 10.4142 20.75 10V6.5ZM14.25 14C14.25 13.5858 14.5858 13.25 15 13.25H20C20.291 13.25 20.5558 13.4183 20.6792 13.6819C20.8026 13.9454 20.7625 14.2566 20.5762 14.4801L16.6013 19.25H20C20.4142 19.25 20.75 19.5858 20.75 20C20.75 20.4142 20.4142 20.75 20 20.75H15C14.709 20.75 14.4442 20.5817 14.3208 20.3181C14.1974 20.0546 14.2375 19.7434 14.4238 19.5199L18.3987 14.75H15C14.5858 14.75 14.25 14.4142 14.25 14Z"
      />
    </svg>
  );
};

export const FilterLetterIcon = (
  props: React.HTMLAttributes<HTMLOrSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7 3.25C7.41421 3.25 7.75 3.58579 7.75 4V18.1893L10.4697 15.4697C10.7626 15.1768 11.2374 15.1768 11.5303 15.4697C11.8232 15.7626 11.8232 16.2374 11.5303 16.5303L7.53033 20.5303C7.23744 20.8232 6.76256 20.8232 6.46967 20.5303L2.46967 16.5303C2.17678 16.2374 2.17678 15.7626 2.46967 15.4697C2.76256 15.1768 3.23744 15.1768 3.53033 15.4697L6.25 18.1893V4C6.25 3.58579 6.58579 3.25 7 3.25ZM14.25 4C14.25 3.58579 14.5858 3.25 15 3.25H20C20.291 3.25 20.5558 3.41834 20.6792 3.68188C20.8026 3.94542 20.7625 4.25658 20.5762 4.48014L16.6013 9.25H20C20.4142 9.25 20.75 9.58579 20.75 10C20.75 10.4142 20.4142 10.75 20 10.75H15C14.709 10.75 14.4442 10.5817 14.3208 10.3181C14.1974 10.0546 14.2375 9.74342 14.4238 9.51986L18.3987 4.75H15C14.5858 4.75 14.25 4.41421 14.25 4ZM17.5 14.75C17.0359 14.75 16.5908 14.9344 16.2626 15.2626C15.9344 15.5908 15.75 16.0359 15.75 16.5V17.25H19.25V16.5C19.25 16.0359 19.0656 15.5908 18.7374 15.2626C18.4092 14.9344 17.9641 14.75 17.5 14.75ZM20.75 16.5C20.75 15.638 20.4076 14.8114 19.7981 14.2019C19.1886 13.5924 18.362 13.25 17.5 13.25C16.638 13.25 15.8114 13.5924 15.2019 14.2019C14.5924 14.8114 14.25 15.638 14.25 16.5V20C14.25 20.4142 14.5858 20.75 15 20.75C15.4142 20.75 15.75 20.4142 15.75 20V18.75H19.25V20C19.25 20.4142 19.5858 20.75 20 20.75C20.4142 20.75 20.75 20.4142 20.75 20V16.5Z"
      />
    </svg>
  );
};
