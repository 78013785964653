// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UploadPhoto_uploadPhoto__dwmXt {
  cursor: pointer;
}
.UploadPhoto_uploadPhoto__dwmXt .UploadPhoto_body__K5kJY {
  border: 1px solid var(--color-white-100);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 10px;
  text-align: center;
  line-height: 21px;
  font-weight: 600;
  aspect-ratio: 1/1;
  box-sizing: border-box;
}
.UploadPhoto_uploadPhoto__dwmXt img {
  border-radius: 10px;
  width: 100%;
  aspect-ratio: 1/1;
}
.UploadPhoto_uploadPhoto__dwmXt input {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/UploadPhoto/UploadPhoto.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;AAAI;EACI,wCAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;EACA,mBAAA;EACA,kBAAA;EACA,iBAAA;EACA,gBAAA;EACA,iBAAA;EACA,sBAAA;AAER;AACI;EACI,mBAAA;EACA,WAAA;EACA,iBAAA;AACR;AAEI;EACI,aAAA;AAAR","sourcesContent":[".uploadPhoto {\n    cursor: pointer;\n    .body {\n        border: 1px solid var(--color-white-100);\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n        justify-content: center;\n        gap: 10px;\n        border-radius: 10px;\n        text-align: center;\n        line-height: 21px;\n        font-weight: 600;\n        aspect-ratio: 1/1;\n        box-sizing: border-box;\n    }\n\n    img {\n        border-radius: 10px;\n        width: 100%;\n        aspect-ratio: 1/1;\n    }\n\n    input {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"uploadPhoto": `UploadPhoto_uploadPhoto__dwmXt`,
	"body": `UploadPhoto_body__K5kJY`
};
export default ___CSS_LOADER_EXPORT___;
