import React from "react";

export const FilterNumberIcon = (
  props: React.HTMLAttributes<HTMLOrSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7 3.25C7.41421 3.25 7.75 3.58579 7.75 4V18.1893L10.4697 15.4697C10.7626 15.1768 11.2374 15.1768 11.5303 15.4697C11.8232 15.7626 11.8232 16.2374 11.5303 16.5303L7.53033 20.5303C7.23744 20.8232 6.76256 20.8232 6.46967 20.5303L2.46967 16.5303C2.17678 16.2374 2.17678 15.7626 2.46967 15.4697C2.76256 15.1768 3.23744 15.1768 3.53033 15.4697L6.25 18.1893V4C6.25 3.58579 6.58579 3.25 7 3.25ZM14.25 4C14.25 3.58579 14.5858 3.25 15 3.25H17C17.4142 3.25 17.75 3.58579 17.75 4V9.25H19C19.4142 9.25 19.75 9.58579 19.75 10C19.75 10.4142 19.4142 10.75 19 10.75H15C14.5858 10.75 14.25 10.4142 14.25 10C14.25 9.58579 14.5858 9.25 15 9.25H16.25V4.75H15C14.5858 4.75 14.25 4.41421 14.25 4ZM17 14.75C16.3096 14.75 15.75 15.3096 15.75 16V18C15.75 18.6904 16.3096 19.25 17 19.25C17.6904 19.25 18.25 18.6904 18.25 18V16C18.25 15.3096 17.6904 14.75 17 14.75ZM14.25 16C14.25 14.4812 15.4812 13.25 17 13.25C18.5188 13.25 19.75 14.4812 19.75 16V18C19.75 19.5188 18.5188 20.75 17 20.75C15.4812 20.75 14.25 19.5188 14.25 18V16Z"
      />
    </svg>
  );
};

export const FilterNumberReverseIcon = (
  props: React.HTMLAttributes<HTMLOrSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.46967 3.46967C6.76256 3.17678 7.23744 3.17678 7.53033 3.46967L11.5303 7.46967C11.8232 7.76256 11.8232 8.23744 11.5303 8.53033C11.2374 8.82322 10.7626 8.82322 10.4697 8.53033L7.75 5.81066V20C7.75 20.4142 7.41421 20.75 7 20.75C6.58579 20.75 6.25 20.4142 6.25 20V5.81066L3.53033 8.53033C3.23744 8.82322 2.76256 8.82322 2.46967 8.53033C2.17678 8.23744 2.17678 7.76256 2.46967 7.46967L6.46967 3.46967ZM17 4.75C16.3096 4.75 15.75 5.30964 15.75 6V8C15.75 8.69036 16.3096 9.25 17 9.25C17.6904 9.25 18.25 8.69036 18.25 8V6C18.25 5.30964 17.6904 4.75 17 4.75ZM14.25 6C14.25 4.48122 15.4812 3.25 17 3.25C18.5188 3.25 19.75 4.48122 19.75 6V8C19.75 9.51878 18.5188 10.75 17 10.75C15.4812 10.75 14.25 9.51878 14.25 8V6ZM14.25 14C14.25 13.5858 14.5858 13.25 15 13.25H17C17.4142 13.25 17.75 13.5858 17.75 14V19.25H19C19.4142 19.25 19.75 19.5858 19.75 20C19.75 20.4142 19.4142 20.75 19 20.75H15C14.5858 20.75 14.25 20.4142 14.25 20C14.25 19.5858 14.5858 19.25 15 19.25H16.25V14.75H15C14.5858 14.75 14.25 14.4142 14.25 14Z"
      />
    </svg>
  );
};
