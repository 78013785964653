import React from "react";

export const WarningIcon = (props: React.HTMLAttributes<HTMLOrSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.492 2.41578C10.9467 2.15083 11.4636 2.01123 11.9898 2.01123C12.5161 2.01123 13.0329 2.15083 13.4876 2.41578C13.9419 2.68045 14.3178 3.06075 14.5773 3.51795C14.5775 3.51843 14.5778 3.51892 14.5781 3.5194L22.5742 17.5126C22.5746 17.5132 22.5749 17.5139 22.5753 17.5145C22.8356 17.9661 22.9726 18.4781 22.9728 18.9994C22.9729 19.5213 22.8357 20.0341 22.575 20.4863C22.3142 20.9385 21.9391 21.3141 21.4873 21.5754C21.0354 21.8367 20.5228 21.9746 20.0008 21.9751H4.0039C3.48103 21.979 2.96635 21.845 2.51173 21.5866C2.05574 21.3274 1.67638 20.9523 1.41209 20.4992C1.14781 20.0462 1.008 19.5313 1.00684 19.0068C1.00568 18.4831 1.14278 17.9684 1.40425 17.5147C1.40466 17.514 1.40507 17.5133 1.40548 17.5126L9.40158 3.5194C9.40193 3.5188 9.40227 3.51819 9.40261 3.51759C9.66205 3.06055 10.0379 2.68038 10.492 2.41578ZM11.9898 3.96123C11.8085 3.96123 11.6304 4.00933 11.4738 4.10061C11.3171 4.1919 11.1875 4.32311 11.0981 4.48085L11.0964 4.48387L3.09637 18.4839L3.09418 18.4877C3.00382 18.6442 2.95644 18.8218 2.95684 19.0025C2.95724 19.1832 3.00541 19.3606 3.09646 19.5167C3.18752 19.6728 3.31822 19.802 3.47533 19.8913C3.63243 19.9806 3.81036 20.0268 3.99106 20.0252L3.99983 20.0251L19.9988 20.0251C20.1787 20.0249 20.3553 19.9774 20.511 19.8874C20.6666 19.7974 20.7959 19.668 20.8857 19.5122C20.9756 19.3564 21.0228 19.1797 21.0228 18.9999C21.0227 18.82 20.9754 18.6434 20.8855 18.4876L20.8833 18.4839L12.8816 4.48086C12.7922 4.32311 12.6625 4.1919 12.5059 4.10061C12.3492 4.00933 12.1711 3.96123 11.9898 3.96123ZM11.9998 8.02513C12.5383 8.02513 12.9748 8.46165 12.9748 9.00013V13.0001C12.9748 13.5386 12.5383 13.9751 11.9998 13.9751C11.4614 13.9751 11.0248 13.5386 11.0248 13.0001V9.00013C11.0248 8.46165 11.4614 8.02513 11.9998 8.02513ZM11.0248 17.0001C11.0248 16.4616 11.4614 16.0251 11.9998 16.0251H12.0098C12.5483 16.0251 12.9848 16.4616 12.9848 17.0001C12.9848 17.5386 12.5483 17.9751 12.0098 17.9751H11.9998C11.4614 17.9751 11.0248 17.5386 11.0248 17.0001Z"
        fill="#FF0000"
      />
    </svg>
  );
};
