import React from "react";

export const RedLogoLeft = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="161"
      height="47"
      viewBox="0 0 161 47"
      fill="none"
    >
      <path
        d="M7.32766 10.4859H5.36512L2.75795 6.19756H1.79817V10.4859H0.122138V4.49397H1.71222L0.122138 1.92389V0.205606H3.45988C3.45988 0.205606 6.81196 0.205606 6.81196 3.20158C6.81196 5.02267 5.58 5.74229 4.60589 6.02133L7.32766 10.4859ZM2.11333 1.92389L3.67476 4.47928C4.11884 4.43522 5.13592 4.21493 5.13592 3.20158C5.13592 1.92389 3.45988 1.92389 3.45988 1.92389H2.11333Z"
        fill="#D70034"
      />
      <path
        d="M15.569 1.92389H10.5552V4.49397H14.7238V6.19756H10.5552V8.76764H15.569V10.4859H8.87913V0.205606H15.569V1.92389Z"
        fill="#D70034"
      />
      <path
        d="M21.985 8.9145C22.9018 8.9145 23.747 8.53266 24.3487 7.91584L25.5233 9.13479C24.6208 10.06 23.3746 10.6328 21.985 10.6328C19.2203 10.6328 16.9569 8.57672 16.9569 5.34576C16.9569 2.24698 19.0484 0.0587448 21.985 0.0587448C23.3746 0.0587448 24.6208 0.631505 25.5233 1.57142L24.3487 2.77568C23.747 2.15887 22.9018 1.77703 21.985 1.77703C19.9222 1.77703 18.6616 3.27501 18.6616 5.34576C18.6616 7.54869 20.1371 8.9145 21.985 8.9145Z"
        fill="#D70034"
      />
      <path
        d="M28.4505 0.161548C28.3645 0.504225 28.2547 0.876274 28.121 1.2777C27.9873 1.67912 27.8393 2.08054 27.6769 2.48196C27.5241 2.87359 27.3713 3.24564 27.2185 3.59811H26.2874C26.3542 3.32397 26.4211 3.03024 26.4879 2.71694C26.5643 2.40363 26.6312 2.09033 26.6885 1.77703C26.7553 1.45393 26.8126 1.14063 26.8604 0.837112C26.9177 0.533598 26.9654 0.25456 27.0036 0H28.3502L28.4505 0.161548Z"
        fill="#D70034"
      />
      <path
        d="M32.5303 10.6328C30.797 10.5887 29.4218 9.56069 29.3931 7.6368H31.0691C31.0978 8.4886 31.7854 8.9145 32.5303 8.9145C33.3039 8.9145 33.9915 8.40048 33.9915 7.59274C33.9915 6.72626 33.2036 6.19756 32.5303 6.16819C32.4444 6.16819 31.7568 6.16819 31.0262 5.83041C29.9804 5.33108 29.3931 4.42053 29.3931 3.20158C29.3931 1.24832 30.9116 0.0587448 32.5303 0.0587448C34.2636 0.0587448 35.5959 1.26301 35.6245 3.01066H33.9628C33.9342 2.15887 33.2895 1.77703 32.5303 1.77703C31.7424 1.77703 31.0691 2.24698 31.0691 3.20158C31.0691 4.05338 31.7854 4.50865 32.5303 4.50865C32.6019 4.50865 33.3039 4.50865 34.0201 4.84643C35.0802 5.34576 35.6532 6.37379 35.6532 7.59274C35.6532 9.56069 34.149 10.6328 32.5303 10.6328Z"
        fill="#D70034"
      />
      <path
        d="M13.6673 42.3294C16.1593 42.3294 18.4567 41.2915 20.0921 39.6149L23.285 42.9282C20.8319 45.4431 17.4443 47 13.6673 47C6.15222 47 0 41.4113 0 32.629C0 24.2059 5.68497 18.2579 13.6673 18.2579C17.4443 18.2579 20.8319 19.8148 23.285 22.3696L20.0921 25.643C18.4567 23.9664 16.1593 22.9285 13.6673 22.9285C8.06019 22.9285 4.63364 27.0003 4.63364 32.629C4.63364 38.6169 8.64426 42.3294 13.6673 42.3294Z"
        fill="#D70034"
      />
      <path
        d="M41.7547 46.6008L40.5477 43.3673H30.9689L29.8007 46.6008H24.8945L35.7583 17.8587L46.6609 46.6008H41.7547ZM35.7583 29.635L32.4485 39.3354H39.068L35.7583 29.635Z"
        fill="#D70034"
      />
      <path
        d="M58.7938 18.6571C58.7938 18.6571 67.8664 18.6571 67.8664 26.8007C67.8664 34.9443 58.7938 34.9443 58.7938 34.9443H54.277V46.6008H49.7212V30.3136H54.0434L49.7212 23.3277V18.6571H58.7938ZM59.3779 30.2737C60.585 30.114 63.3496 29.5152 63.3496 26.8007C63.3496 23.3277 58.7938 23.3277 58.7938 23.3277H55.1336L59.3779 30.2737Z"
        fill="#D70034"
      />
      <path
        d="M81.4172 22.9285H78.38V42.3294H81.5729V46.6008H70.6313V42.3294H73.8242V22.9285H70.7871V18.6571H81.4172V22.9285Z"
        fill="#D70034"
      />
      <path
        d="M99.7029 18.6571V22.9285H93.979V46.6008H89.4621V22.9285H83.7382V18.6571H99.7029Z"
        fill="#D70034"
      />
      <path
        d="M117.273 46.6008L116.066 43.3673H106.488L105.319 46.6008H100.413L111.277 17.8587L122.18 46.6008H117.273ZM111.277 29.635L107.967 39.3354H114.587L111.277 29.635Z"
        fill="#D70034"
      />
      <path
        d="M129.796 41.9302H139.881V46.6008H125.24V18.6571H129.796V41.9302Z"
        fill="#D70034"
      />
      <path
        d="M152.301 47C147.589 46.8802 143.851 44.0859 143.774 38.8564H148.329C148.407 41.1717 150.276 42.3294 152.301 42.3294C154.404 42.3294 156.273 40.9322 156.273 38.7366C156.273 36.3814 154.131 34.9443 152.301 34.8644C152.067 34.8644 150.198 34.8645 148.212 33.9463C145.37 32.589 143.774 30.114 143.774 26.8007C143.774 21.4914 147.901 18.2579 152.301 18.2579C157.012 18.2579 160.634 21.5313 160.712 26.2817H156.195C156.117 23.9664 154.365 22.9285 152.301 22.9285C150.159 22.9285 148.329 24.2059 148.329 26.8007C148.329 29.116 150.276 30.3535 152.301 30.3535C152.496 30.3535 154.404 30.3535 156.351 31.2717C159.232 32.629 160.789 35.4233 160.789 38.7366C160.789 44.0859 156.701 47 152.301 47Z"
        fill="#D70034"
      />
    </svg>
  );
};
