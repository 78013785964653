import React, { useEffect, useState } from "react";

export const useMedia = () => {
  const [isDesktop, setIsDesktop] = useState(
    window.matchMedia("(min-width: 1200px)").matches
  );
  const [isTablet, setIsTablet] = useState(
    window.matchMedia("(max-width: 1199px)").matches
  );
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 780px)").matches
  );

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.matchMedia("(min-width: 1200px)").matches);
      setIsTablet(window.matchMedia("(max-width: 1199px)").matches);
      setIsMobile(window.matchMedia("(max-width: 780px)").matches);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { isDesktop, isTablet, isMobile };
};
