import React from "react";

export const useNumbers = () => {
  const splitNumber = (num: number) => {
    return num.toLocaleString("en-US").replace(/,/g, " ");
  };

  return {
    splitNumber,
  };
};
