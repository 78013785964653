import React, { useContext } from "react";

import styles from "./Header.module.scss";
import { RedLogo } from "../../icons/RedLogo";
import { Avatar } from "../Avatar/Avatar";
import { ProfileContext } from "../../context/ProfileContext";
import { Button } from "../Button/Button";
import { SignInIcon } from "../../icons/SignInIcon";

interface HeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

export const Header = (props: HeaderProps) => {
  const { children } = props;
  const { profile } = useContext(ProfileContext);
  return (
    <div className={styles.header}>
      {children}
      <RedLogo />
      {profile ? <Avatar /> : <Button icon={<SignInIcon />}>Войти</Button>}
    </div>
  );
};
