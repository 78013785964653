import React from "react";

export const useProject = () => {
  const getProjectIncomeType = (name: string) => {
    if (name === "buy_part") {
      return "Покупка доли/бизнеса";
    }

    if (name === "by_maturity_date") {
      return "К сроку погашения";
    }

    if (name === "monthly") {
      return "Ежемесячные выплаты";
    }
  };

  const getProjectInvestType = (name: string) => {
    if (name === "loan_agreement") {
      return "Договор займа.";
    }

    if (name === "asset_management") {
      return "Управление активами.";
    }

    if (name === "purchase_share_business") {
      return "Покупка доли/бизнеса.";
    }
  };

  const getProjectType = (name: string) => {
    if (name === "develop") {
      return "Разработка.";
    }

    return "Действующий с прибылью";
  };
  return {
    getProjectIncomeType,
    getProjectInvestType,
    getProjectType,
  };
};
