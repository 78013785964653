import React from "react";

export const TgIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.23693 15.8333C11.9017 13.8075 15.007 12.4614 16.5664 11.8083C21.0045 9.95575 21.9375 9.63589 22.5372 9.62256C22.6705 9.62256 22.9637 9.64921 23.1636 9.80915C23.3236 9.94243 23.3635 10.1157 23.3902 10.249C23.4169 10.3822 23.4435 10.6621 23.4169 10.8754C23.177 13.4076 22.1374 19.5518 21.6043 22.3772C21.3777 23.5767 20.9379 23.9766 20.5114 24.0166C19.5785 24.0965 18.8721 23.4035 17.9791 22.8171C16.5664 21.8974 15.78 21.3243 14.4073 20.4181C12.8213 19.3785 13.8475 18.8054 14.7538 17.8725C14.9937 17.6326 19.0853 13.9008 19.1653 13.5676C19.1786 13.5276 19.1786 13.3677 19.0853 13.2877C18.992 13.2077 18.8588 13.2344 18.7521 13.261C18.6055 13.2877 16.3665 14.7804 12.0083 17.7258C11.3685 18.1657 10.7954 18.3789 10.2757 18.3656C9.70257 18.3523 8.60969 18.0457 7.78337 17.7792C6.78379 17.4593 5.98412 17.286 6.05076 16.7263C6.09074 16.4331 6.49057 16.1398 7.23693 15.8333Z"
        fill="#F5F5F5"
      />
    </svg>
  );
};
